import { template as template_040688ddc9fa4958a7d2d884987daf83 } from "@ember/template-compiler";
const SidebarSectionMessage = template_040688ddc9fa4958a7d2d884987daf83(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
