import { template as template_f374ecf41c754f2f9c190dc725624fdd } from "@ember/template-compiler";
const FKLabel = template_f374ecf41c754f2f9c190dc725624fdd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
