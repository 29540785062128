import { template as template_2049c8f10c054e07af5adef22ca3cb30 } from "@ember/template-compiler";
const WelcomeHeader = template_2049c8f10c054e07af5adef22ca3cb30(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
