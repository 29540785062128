import { template as template_dd0c258c83dd4d5ab96a99d2c3bfdbb7 } from "@ember/template-compiler";
const FKText = template_dd0c258c83dd4d5ab96a99d2c3bfdbb7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
