import { template as template_96058bbf6a3c46079d113f3d9be255e2 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_96058bbf6a3c46079d113f3d9be255e2(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
