import { template as template_f3fac418abc64055b0958299dfc268cf } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_f3fac418abc64055b0958299dfc268cf(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
